/*!
* Raiger Archtektur
* https://raiger.design
* Copyright Krzysztof Jeziorny 2023
* https://jeziorny.net
*/

// styles
@use "variables_design";

// overrides
@use "navbar_design";
