/*!
* Raiger Archtektur
* https://raiger.design
* Copyright Krzysztof Jeziorny 2023
* https://jeziorny.net
*/
:where(html) {
  --size-000: -.5rem;
  --size-00: -.25rem;
  --size-1: .25rem;
  --size-2: .5rem;
  --size-3: 1rem;
  --size-4: 1.25rem;
  --size-5: 1.5rem;
  --size-6: 1.75rem;
  --size-7: 2rem;
  --size-8: 3rem;
  --size-9: 4rem;
  --size-10: 5rem;
  --size-11: 7.5rem;
  --size-12: 10rem;
  --size-13: 15rem;
  --size-14: 20rem;
  --size-15: 30rem;
  --size-px-000: -8px;
  --size-px-00: -4px;
  --size-px-1: 4px;
  --size-px-2: 8px;
  --size-px-3: 16px;
  --size-px-4: 20px;
  --size-px-5: 24px;
  --size-px-6: 28px;
  --size-px-7: 32px;
  --size-px-8: 48px;
  --size-px-9: 64px;
  --size-px-10: 80px;
  --size-px-11: 120px;
  --size-px-12: 160px;
  --size-px-13: 240px;
  --size-px-14: 320px;
  --size-px-15: 480px;
  --size-fluid-1: clamp(.5rem, 1vw, 1rem);
  --size-fluid-2: clamp(1rem, 2vw, 1.5rem);
  --size-fluid-3: clamp(1.5rem, 3vw, 2rem);
  --size-fluid-4: clamp(2rem, 4vw, 3rem);
  --size-fluid-5: clamp(4rem, 5vw, 5rem);
  --size-fluid-6: clamp(5rem, 7vw, 7.5rem);
  --size-fluid-7: clamp(7.5rem, 10vw, 10rem);
  --size-fluid-8: clamp(10rem, 20vw, 15rem);
  --size-fluid-9: clamp(15rem, 30vw, 20rem);
  --size-fluid-10: clamp(20rem, 40vw, 30rem);
  --size-content-1: 20ch;
  --size-content-2: 45ch;
  --size-content-3: 60ch;
  --size-header-1: 20ch;
  --size-header-2: 25ch;
  --size-header-3: 35ch;
  --size-xxs: 240px;
  --size-xs: 360px;
  --size-sm: 480px;
  --size-md: 768px;
  --size-lg: 1024px;
  --size-xl: 1440px;
  --size-xxl: 1920px;
  --size-relative-000: -.5ch;
  --size-relative-00: -.25ch;
  --size-relative-1: .25ch;
  --size-relative-2: .5ch;
  --size-relative-3: 1ch;
  --size-relative-4: 1.25ch;
  --size-relative-5: 1.5ch;
  --size-relative-6: 1.75ch;
  --size-relative-7: 2ch;
  --size-relative-8: 3ch;
  --size-relative-9: 4ch;
  --size-relative-10: 5ch;
  --size-relative-11: 7.5ch;
  --size-relative-12: 10ch;
  --size-relative-13: 15ch;
  --size-relative-14: 20ch;
  --size-relative-15: 30ch;
}

:where(html) {
  --font-system-ui: system-ui, sans-serif;
  --font-transitional: Charter, Bitstream Charter, Sitka Text, Cambria, serif;
  --font-old-style: Iowan Old Style, Palatino Linotype, URW Palladio L, P052, serif;
  --font-humanist: Seravek, Gill Sans Nova, Ubuntu, Calibri, DejaVu Sans, source-sans-pro, sans-serif;
  --font-geometric-humanist: Avenir, Montserrat, Corbel, URW Gothic, source-sans-pro, sans-serif;
  --font-classical-humanist: Optima, Candara, Noto Sans, source-sans-pro, sans-serif;
  --font-neo-grotesque: Inter, Roboto, Helvetica Neue, Arial Nova, Nimbus Sans, Arial, sans-serif;
  --font-monospace-slab-serif: Nimbus Mono PS, Courier New, monospace;
  --font-monospace-code: Dank Mono,Operator Mono, Inconsolata, Fira Mono, ui-monospace, SF Mono, Monaco, Droid Sans Mono, Source Code Pro, Cascadia Code, Menlo, Consolas, DejaVu Sans Mono, monospace;
  --font-industrial: Bahnschrift, DIN Alternate, Franklin Gothic Medium, Nimbus Sans Narrow, sans-serif-condensed, sans-serif;
  --font-rounded-sans: ui-rounded, Hiragino Maru Gothic ProN, Quicksand, Comfortaa, Manjari, Arial Rounded MT, Arial Rounded MT Bold, Calibri, source-sans-pro, sans-serif;
  --font-slab-serif: Rockwell, Rockwell Nova, Roboto Slab, DejaVu Serif, Sitka Small, serif;
  --font-antique: Superclarendon, Bookman Old Style, URW Bookman, URW Bookman L, Georgia Pro, Georgia, serif;
  --font-didone: Didot, Bodoni MT, Noto Serif Display, URW Palladio L, P052, Sylfaen, serif;
  --font-handwritten: Segoe Print, Bradley Hand, Chilanka, TSCu_Comic, casual, cursive;
  --font-sans: var(--font-system-ui);
  --font-serif: ui-serif, serif;
  --font-mono: var(--font-monospace-code);
  --font-weight-1: 100;
  --font-weight-2: 200;
  --font-weight-3: 300;
  --font-weight-4: 400;
  --font-weight-5: 500;
  --font-weight-6: 600;
  --font-weight-7: 700;
  --font-weight-8: 800;
  --font-weight-9: 900;
  --font-lineheight-00: .95;
  --font-lineheight-0: 1.1;
  --font-lineheight-1: 1.25;
  --font-lineheight-2: 1.375;
  --font-lineheight-3: 1.5;
  --font-lineheight-4: 1.75;
  --font-lineheight-5: 2;
  --font-letterspacing-0: -.05em;
  --font-letterspacing-1: .025em;
  --font-letterspacing-2: .050em;
  --font-letterspacing-3: .075em;
  --font-letterspacing-4: .150em;
  --font-letterspacing-5: .500em;
  --font-letterspacing-6: .750em;
  --font-letterspacing-7: 1em;
  --font-size-00: .5rem;
  --font-size-0: .75rem;
  --font-size-1: 1rem;
  --font-size-2: 1.1rem;
  --font-size-3: 1.25rem;
  --font-size-4: 1.5rem;
  --font-size-5: 2rem;
  --font-size-6: 2.5rem;
  --font-size-7: 3rem;
  --font-size-8: 3.5rem;
  --font-size-fluid-0: clamp(.75rem, 2vw, 1rem);
  --font-size-fluid-1: clamp(1rem, 4vw, 1.5rem);
  --font-size-fluid-2: clamp(1.5rem, 6vw, 2.5rem);
  --font-size-fluid-3: clamp(2rem, 9vw, 3.5rem);
}

:where(html) {
  --gray-0: #f8f9fa;
  --gray-1: #f1f3f5;
  --gray-2: #e9ecef;
  --gray-3: #dee2e6;
  --gray-4: #ced4da;
  --gray-5: #adb5bd;
  --gray-6: #868e96;
  --gray-7: #495057;
  --gray-8: #343a40;
  --gray-9: #212529;
  --gray-10: #16191d;
  --gray-11: #0d0f12;
  --gray-12: #030507;
}

:root {
  --gap: 1rem;
  --col: 15rem;
  --aggro1: #4c5e89;
  --aggro2: #7f88a7;
  --accent: var(--aggro1);
  --border-width: calc(1rem * 0.1);
  --dark: #292b2f;
  --light: #efedf1;
  accent-color: var(--accent);
}

/*!
* Raiger Archtektur
* https://raiger.design
* Copyright Krzysztof Jeziorny 2023
* https://jeziorny.net
*/
.navbar-brand a span.site-name {
  background: transparent url("../img/design/logo.svg") 0 50% no-repeat;
}
@media (prefers-color-scheme: light), (prefers-color-scheme: no-preference) {
  .navbar-brand a span.site-name {
    background-image: url("../img/design/logo.svg");
  }
}
[data-theme=light] .navbar-brand a span.site-name {
  background-image: url("../img/design/logo.svg");
}
@media (prefers-color-scheme: dark) {
  .navbar-brand a span.site-name {
    background-image: url("../img/design/logo_neg.svg");
  }
}
[data-theme=dark] .navbar-brand a span.site-name {
  background-image: url("../img/design/logo_neg.svg");
}
.navbar-brand a span.slogan {
  text-indent: 0;
  font-size: var(--font-size-fluid-2);
}