@use "variables_design";

// navbar, logo
.navbar {
  &-brand {
    a {
      span {
        &.site-name {
          background: transparent url(variables_design.$logo) 0 50% no-repeat;

          // default: light / no pref
          @media (prefers-color-scheme: light), (prefers-color-scheme: no-preference) {
            background-image: url(variables_design.$logo);
          }

          [data-theme="light"] & {
            background-image: url(variables_design.$logo);
          }

          // dark
          @media (prefers-color-scheme: dark) {
            background-image: url(variables_design.$logo-neg);
          }

          [data-theme="dark"] & {
            background-image: url(variables_design.$logo-neg);
          }
        }

        &.slogan {
          text-indent: 0;
          font-size: var(--font-size-fluid-2);
        }
      }
    }
  }
}
