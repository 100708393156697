// Import Open Props (without their .css extension)
// https://open-props.style/
@use "../../../../node_modules/open-props/src/props.sizes";
@use "../../../../node_modules/open-props/src/props.fonts" as props2;

// open props colors
@use "../../../../node_modules/open-props/src/props.gray" as props3;

:root {
  // Grid
  --gap: 1rem;
  --col: 15rem;
  --aggro1: #4c5e89;
  --aggro2: #7f88a7;
  --accent: var(--aggro1);
  --border-width: calc(1rem * 0.1);
  --dark: #292b2f;
  --light: #efedf1;

  accent-color: var(--accent);
}

// logos
$logo: "../img/design/logo.svg";
$logo-neg: "../img/design/logo_neg.svg";
